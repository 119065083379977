import React from "react";
import SEO from "../components/seo";
import { ButtonLink } from "../components/button";
import { Text } from "../components/typography";
import Layout from "../components/layout";
import { useTheme } from "../components/ThemeProvider";

function IndexPage(props) {
  const theme = useTheme();
  return (
    <Layout {...props}>
      <SEO title="Home" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />
      <Text variant="large_C">
        Hey people{" "}
        <span role="img" aria-label="wave emoji">
          👋
        </span>
      </Text>
      <Text variant="medium_C">
        Welcome to Hello Deary – the personal blog of Michael Barakat. Front End
        Developer living in Seattle.
      </Text>
      <Text variant="medium_C">
        I'm here to talk about the good stuff: making a career, changing roles,
        learning to code, stress, anxiety, healing your mind, mistakes,
        problems, wealth, poverty, racism... just a few things that have been
        running through my head.
      </Text>
      <Text variant="medium_C">
        This is a blog about coding, living the tech life for better or for
        worse, leveraging your skills, being who you are, and bridging design
        with other disciplines, and probably a few other things too that just
        don't come to mind right now 😉
      </Text>
      <ButtonLink style={{ marginTop: 25, display: "block" }} to={"/blog/"}>
        On to Blog
      </ButtonLink>
    </Layout>
  );
}

export default IndexPage;
