import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { Theme } from "../theme/theme";
import { useTheme } from "./ThemeProvider";

interface ButtonWrapperProps {
  buttonProps: any;
  theme: Theme;
}

const ButtonWrapper = styled(Link)((props) => ({
  maxWidth: "fit-content",
  border: "none",
  textAlign: "center",
  boxSizing: "border-box",
  textDecoration: "none",
  padding: "10px 25px",
  cursor: "pointer",
  textTransform: "uppercase",
  letterSpacing: 2,
  background: props.theme.semanticColors.buttonBackground || "black",
  color: props.theme.semanticColors.bodyText,
  "&:focus": {
    outlineColor: props.theme.semanticColors.bodyText,
    outlineStyle: "dashed",
    outlineWidth: 2,
    outlineOffset: 2,
  },
  "&:hover": {
    boxShadow: "inset 0 0 100px 100px rgba(255, 255, 255, 0.25)",
  },
}));
const ButtonPrimaryBase = styled.button((props) => ({
  maxWidth: "fit-content",
  border: "none",
  textAlign: "center",
  boxSizing: "border-box",
  textDecoration: "none",
  padding: "10px 25px",
  cursor: "pointer",
  textTransform: "uppercase",
  letterSpacing: 2,
  background: props.theme.semanticColors.buttonBackground || "black",
  color: props.theme.semanticColors.bodyText,
  "&:focus": {
    outlineColor: props.theme.semanticColors.bodyText,
    outlineStyle: "dashed",
    outlineWidth: 2,
    outlineOffset: 2,
  },
  "&:hover": {
    boxShadow: "inset 0 0 100px 100px rgba(255, 255, 255, 0.25)",
  },
}));

function ButtonPrimary(props) {
  const theme = useTheme();
  return (
    <ButtonPrimaryBase theme={theme} {...props}>
      {props.children}
    </ButtonPrimaryBase>
  );
}

function ButtonLink(props) {
  const theme = useTheme();
  return (
    <ButtonWrapper to={props.to} theme={theme} style={props.style} {...props}>
      {props.children}
    </ButtonWrapper>
  );
}

export { ButtonLink, ButtonPrimary };
